import Vue from 'vue';

import {
    Select,
    Input,
    Option,
    InfiniteScroll,
    Form,
    FormItem,
    Button,
    Menu,
    Submenu,
    MenuItem,
    Cascader,
    Image,
    Breadcrumb,
    BreadcrumbItem,
    DatePicker,
    Table,
    TableColumn,
    Dialog,
    Message,
    Tree,
    Drawer,
    Upload,
    RadioGroup,
    Radio,
    MessageBox,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Transfer,
    Tooltip,
    Loading,
    Pagination,
    Popconfirm,
} from 'element-ui';

Vue.use(Select)
Vue.use(Input)
Vue.use(Option)
Vue.use(InfiniteScroll)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Cascader)
Vue.use(Image)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Tree)
Vue.use(Drawer)
Vue.use(Upload)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Transfer)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Popconfirm)

Vue.prototype.$loading = Loading.service;
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
