<template>
  <div id="app">
    <!-- <v-scale-screen width="1920" height="1080"> -->
      <router-view />
    <!-- </v-scale-screen> -->
  </div>
</template>

<script>
export default {
};
</script>

<style lang="less">
::-webkit-scrollbar {
  display: none;
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}

.el-select-dropdown {
  left: -1px !important;
}

.el-popper {
  background: #050b24 !important;
  border-color: #2d4198 !important;
  border-radius: 0 !important;

  .el-scrollbar {
    border-color: #2d4198;
    color: #cad5ff;

    .el-scrollbar__wrap {
      .el-scrollbar__view {
        li {
          color: #cad5ff;
        }

        li.selected {
          background: transparent !important;
        }

        li:not(.is-disabled):focus,
        li:not(.is-disabled):hover {
          background: #032e5e !important;
        }

        .hover {
          background: transparent !important;
        }
        .selected {
          color: #fff;
        }

        .in-active-path,
        .is-active {
          color: #fff;
        }
      }
    }
  }

  .popper__arrow {
    border-top-width: 0 !important;
    border-bottom-color: #2d4198 !important;
  }

  .popper__arrow::after {
    border: none !important;
  }
}

.el-picker-panel {
  margin-left: -1px !important;

  .el-picker-panel__body-wrapper {
    .el-picker-panel__body {
      .el-picker-panel__content {
        border-right-color: #2d4198;

        .el-date-range-picker__header,
        .el-picker-panel__icon-btn {
          color: #cad5ff;
        }

        .el-date-table > tbody > tr > th {
          color: #cad5ff;
          border-color: #cad5ff;
        }

        .el-date-table > tbody > .el-date-table__row > .in-range > div {
          background: #032e5e;
          color: #fff;
        }
      }
    }
  }
}

html,
body,
#app {
  height: 100%;
  user-select: none;
}
</style>

