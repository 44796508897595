import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home')
  },
  {
    path: '/netCage',
    name: 'NetCage',
    component: () => import('@/views/netCage')
  },
  {
    path: '/dataAnalysis',
    name: 'DataAnalysis',
    component: () => import('@/views/dataAnalysis')
  },
  {
    path: '/warning',
    name: 'Warning',
    component: () => import('@/views/warning')
  },
  {
    path: '/realTime',
    name: 'RealTime',
    component: () => import('@/views/realTime')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin')
  },
  {
    path: '/state',
    name: 'State',
    component: () => import('@/views/state')
  },
  {
    path: '/battery',
    name: 'Battery',
    component: () => import('@/views/battery')
  },
  {
    path: '/environment',
    name: 'Environment',
    component: () => import('@/views/environment')
  },
  {
    path: '/breed',
    name: 'Breed',
    component: () => import('@/views/breed')
  },
  {
    path: '/control',
    name: 'Control',
    component: () => import('@/views/control')
  },
]

const createRouter = () => new Router({
  mode: 'hash', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = createRouter()

export default router
